<i18n locale="th" lang="yaml" >
page.title : "{name} :: ข้อมูลรุ่นของ Drone"
tab.base : "สรุปข้อมูล"
tab.parts : "อะไหล่"
tab.lots : "Lot"

action.title : "Drone Model Action"

action.duplicate : "Duplicate Model"
action.duplicate.tooltip : "ทำการสร้าง Drone รุ่นใหม่ โดยใช้รุ่นนี้เป็นต้นแบบ (อะไหล่)"

action.draft_to_active : "เริ่มนำไปใช้งาน"
action.draft_to_active.tooltip : "เปลี่ยนสถานะของ Drone รุ่นนี้ ให้เริ่มใช้งานได้ (เพิ่ม Drone , เพิ่ม Lot ได้)"
action.draft_to_active.title : "เริ่มนำไปใช้งาน"
action.draft_to_active.content : "ต้องการปรับสถานะ ให้ Drone รุ่นนี้ ({name}) เริ่มใช้งาน ? จะไม่สามารถแก้ไขข้อมูลอะไหล่ตั้งต้นได้แล้ว"
action.draft_to_active.ok : "@:common.confirm"
action.draft_to_active.success : "Drone รุ่น {name} สามารถใช้งานได้แล้ว"

action.inactive_to_active : "นำกลับมาใช้งานใหม่"
action.inactive_to_active.tooltip : "นำ Drone รุ่นนี้กลับมาใช้งานใหม่อีกครั้ง"
action.inactive_to_active.title : "นำกลับมาใช้งานใหม่"
action.inactive_to_active.content : "นำ Drone รุ่น {name} กลับมาใช้งานอีกครั้ง ?"
action.inactive_to_active.ok : "@:common.confirm"
action.inactive_to_active.success : "Drone รุ่น {name} สามารถใช้งานได้แล้ว"

action.to_inactive : "ยกเลิกการใช้งานชั่วคราว"
action.to_inactive.tooltip : "เปลี่ยนสถานะของ Drone รุ่นนี้ ให้ระงับใช้งานชั่วคราว คือ ไม่สามารถเพิ่มตัวใหม่ได้"
action.to_inactive.title : "ยกเลิกการใช้งานชั่วคราว"
action.to_inactive.content : "ต้องการยกเลิก Drone รุ่น {name} ชั่วคราว ?"
action.to_inactive.ok : "@:common.confirm"
action.to_inactive.success : "Drone รุ่น {name} ถูกยกเลิกใช้งานชั่วคราว"

action.active_to_draft : "ปรับกลับเป็น Draft"
action.active_to_draft.tooltip : "ทำการปรับสถานะเป็น Draft เพื่อแก้ไขข้อมูลก่อน"
action.active_to_draft.title : "ปรับกลับเป็น Draft"
action.active_to_draft.content : "ต้องการปรับ Drone รุ่น {name} ให้เป็น Draft ?"
action.active_to_draft.ok : "@:common.confirm"
action.active_to_draft.success : "Drone รุ่น {name} ถูกปรับเป็น Draft เรียบร้อย"
action.active_to_draft.cannot : "ไม่สามารถปรับเป็น Draft ได้ เนื่องจากมีข้อมูล Lot ในระบบแล้ว"

action.delete : "ลบข้อมูล Drone รุ่นนี้ออกจากระบบ"
action.delete.tooltip : "ทำการลบ Drone ออกจากระบบ โดยจะลบได้เฉพาะ Drone รุ่นที่ไม่มีข้อมูล Drone เท่านั้น"
action.delete.title : "ลบข้อมูล"
action.delete.content : "ต้องการลบข้อมูล Drone รุ่น {name} ? คำเตือน ไม่สามารถนำข้อมูลกลับมาได้"
action.delete.ok : "@:common.confirm"
action.delete.success : "Drone รุ่น {name} ถูกลบข้อมูลออกจากระบบแล้ว"
action.delete.cannot : "ไม่สามารถลบข้อมูลได้ มีข้อมูล Drone ในระบบ (ใช้การยกเลิกชั่วคราวแทน)"
</i18n>

<template>
	<div class="page page-padding page-fit-mobile page-fit-tablet">
		<div class="mylayout-left-right responsive">
			<div class="mylayout-left">
				<DroneModelCard
					:loading="pageLoading"
					:model="model"
					:num-drones="numDrones"
					:show-update="!$route.meta.hideAction" />

				<a-card
					v-if="canMakeAction"
					:loading="pageLoading"
					:bordered="false"
					style="margin-top:24px"
					:title="$t('action.title')">
					<div class="mybutton-group-vertical">
						<a-button v-if="!isDroneModelActive" :title="$t('action.inactive_to_active.tooltip')"
							@click="askInactiveToActive">
							{{$t('action.inactive_to_active')}}
						</a-button>
						<a-button :disabled="!isDroneModelActive" :title="$t('action.to_inactive.tooltip')"
							@click="askToInactive">
							{{$t('action.to_inactive')}}
						</a-button>
						<!-- <a-button :disabled="numDrones > 0" :title="$t('action.delete.tooltip')"
							type="danger" @click="askToDelete">
							<a-icon type="delete" />
							{{$t('action.delete')}}
						</a-button> -->
					</div>
				</a-card>
			</div>

			<div class="mylayout-right">
				<template v-if="!$route.meta.hideTab">
					<a-menu
						mode="horizontal"
						type="inner"
						class="mytab-menu"
						:selected-keys="selectedMenu">
						<a-menu-item key="drone_model/base">
							<my-router-link name='drone_model/base' :param-value="$route.params.id">
								<a-icon type="pie-chart" /> {{$t('tab.base')}}
							</my-router-link>
						</a-menu-item>
						<a-menu-item key="drone_model/lots">
							<my-router-link name='drone_model/lots' :param-value="$route.params.id">
								{{$t('tab.lots')}}
							</my-router-link>
						</a-menu-item>
						<a-menu-item key="drone_model/parts">
							<my-router-link name='drone_model/parts' :param-value="$route.params.id">
								<a-icon type="deployment-unit" /> {{$t('tab.parts')}}
							</my-router-link>
						</a-menu-item>
					</a-menu>
					<div class="mytab-content">
						<keep-alive>
							<router-view :model="model" :num-lots="numLots" :num-drones="numDrones" ></router-view>
						</keep-alive>
					</div>
				</template>
				<template v-else>
					<router-view :model="model" :num-lots="numLots" :num-drones="numDrones"></router-view>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import LoginInfoMixin from "@mixins/LoginInfoMixin.vue"
import DroneModelCard from "@components/drone/DroneModelCard.vue"
import axios from "axios"
import {Menu} from "ant-design-vue"
export default {
	components : {
		DroneModelCard ,
		"a-menu" : Menu, "a-menu-item" : Menu.Item ,
	} ,
	mixins : [PageMixin,LoginInfoMixin] ,
	page() {
		return {
			title : this.$t('page.title',{name:this.model.name})
		}
	} ,
	data() {
		return {
			model : {} ,
			numDrones : 0,
			numLots : 0,
			selectedMenu : []
		}
	} ,
	computed : {
		canMakeAction() {
			return !this.$route.meta.hideAction &&
				this.model.id && this.$authorize('update','droneModel')
		} ,
		isDroneModelActive(){
			return !this.model.deletedAt
		}
	} ,

	watch : {
		$route(newVal) {
			if (!this.model || newVal.params.id != this.model.id) {
				this.fetchData()
			}
			this.updateSelectedMenu()
		}
	} ,
	mounted() {
		this.updateSelectedMenu()
		this.fetchData()
	} ,
	methods : {
		updateSelectedMenu() {
			this.selectedMenu = [this.$route.name]
		} ,
		fetchData() {
			const modelId = this.$route.params.id;
			if (!modelId)
				return
			this.showPageLoading()
			this.clearBreadcrumbParams('modelName')
			axios.get("/api/drone-models/"+modelId).then((response) => {
				this.model = response.data.data.droneModel;
				this.numDrones = response.data.data.numDrones;
				this.numLots = response.data.data.numLots;

				this.addBreadcrumbParams({'modelName' : this.model.name})
			}).catch((error) => {
				this.fetchError(error);
			}).finally(()=> {
				this.hidePageLoading()
			})
		} ,
		// askDraftToActive() {
		// 	this.changeDroneModelStatus('action.draft_to_active','draft_to_active')
		// } ,
		askInactiveToActive() {
			this.changeDroneModelStatus('action.inactive_to_active','inactive_to_active')
		} ,
		askToInactive() {
			this.changeDroneModelStatus('action.to_inactive','to_inactive')
		} ,
		// askActiveToDraft() {
		// 	if (this.numLots > 0) {
		// 		this.$message.info(this.$t('action.active_to_draft.cannot'))
		// 		return;
		// 	}
		// 	this.changeDroneModelStatus('action.active_to_draft','active_to_draft')
		// } ,
		changeDroneModelStatus(key,action) {
			const formData = {
				'action' : action
			}
			this.$confirm({
				title : this.$t(key+'.title') ,
				content : this.$t(key+'.content',{"name" : this.model.name}) ,
				okText : this.$t(key+'.ok') ,
				maskClosable : true,
				onOk: ()=> {
					axios.post("/api/drone-models/"+this.model.id+"/status",formData).then((response) => {
						this.model = response.data.data.droneModel;
						this.$message.success(this.$t(key+'.success',{"name" : this.model.name}))
					})
				}
			})
		} ,
		askToDelete() {
			if (this.numDrones > 0) {
				this.$message.info(this.$t('action.delete.cannot'))
				return;
			}
			this.$confirm({
				title : this.$t('action.delete.title') ,
				content : this.$t('action.delete.content',{"name" : this.model.name}) ,
				okText : this.$t('action.delete.ok') ,
				maskClosable : true,
				onOk: ()=> {
					axios.delete("/api/drone-models/"+this.model.id).then((response) => {
						this.$router.push({name:'drone_model/list'})
						this.$message.success(this.$t('action.delete.success',{"name" : this.model.name}))
					})
				}
			})
		}
	}
}
</script>

<style lang="less">
.model-action-title {
	text-decoration: underline;
	font-weight: 600;
	margin-bottom : 8px;
}
</style>
