<i18n src="@i18n/drone/drone_model.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
drone_model.field.drone_name_prefix : "ชื่อเรียก Drone"
drone_model.field.num_drones : "จำนวน Drone"
warranty_duration.display : '{count} @:common.day'
warranty_duration.display.zero : 'ไม่รับประกัน'
warranty_flight_time.display : '{count} @:common.hour'
warranty_flight_time.display.zero : 'ไม่รับประกัน'
num_drones.display : '{count} @:common.drone.unit'
num_drones.display.zero : '-'
</i18n>

<template>
	<a-card
		:bordered="false"
		:class="cardCssClasses"
		:loading="loading">
		<a-button
			v-if="canUpdate"
			class="mycard-button-action"
			shape="circle"
			icon="edit"
			@click="handleUpdateClick" />
		<div class="mycard-content">
			<div class="mycard-header">
				<ImageLoader
					class="mycard-image"
					:src="model.image_url"
					border-type="round">
					<DroneIcon class="anticon" />
				</ImageLoader>
			</div>
			<ul class="mycard-details">
				<li>
					<span class="label">{{$t('drone_model.field.name')}}</span>
					<span class="value">{{model.name}}</span>
				</li>
				<li>
					<span class="label">{{$t('drone_model.field.display_name')}}</span>
					<span class="value">{{model.displayName}}</span>
				</li>
				<li>
					<span class="label">{{$t('drone_model.field.drone_name_prefix')}}</span>
					<span class="value"><span class="text-secondary text-bold">{{model.displayNamePrefix}}</span>0XX</span>
				</li>
				<!-- <li>
					<span class="label">{{$t('drone_model.field.drone_type')}}</span>
					<span class="value">{{$tenum('drone_type',model.drone_type)}}</span>
				</li> -->
				<!-- <li>
					<span class="label">{{$t('drone_model.field.hgr_ref_code')}}</span>
					<span class="value">{{model.hgr_ref_code | emptyCheck}}</span>
				</li> -->
				<!-- <li>
					<span class="label">{{$t('drone_model.field.firmware_set')}}</span>
					<span class="value">{{model.firmwareSet ? model.firmwareSet.name : $t('common.unspecify')}}</span>
				</li> -->
				<li>
					<span class="label">{{$t('drone_model.field.num_drones')}}</span>
					<span class="value">{{$tc_my('num_drones.display',numDrones)}}</span>
				</li>
				<li>
					<span class="label">{{$t('drone_model.field.status')}}</span>
					<span class="value">{{$tenum('drone_model.status', droneModelStatus)}}</span>
				</li>
				<li class="separator"></li>
				<li>
					<span class="label">{{$t('drone_model.field.release_date')}}</span>
					<span class="value">{{model.releaseDate | date}}</span>
				</li>
				<li>
					<span class="label">{{$t('drone_model.field.warranty_duration')}}</span>
					<span class="value">{{$tc_my('warranty_duration.display',model.warrantyDuration)}}</span>
				</li>
				<li>
					<span class="label">{{$t('drone_model.field.warranty_flight_time')}}</span>
					<span class="value">{{$tc_my('warranty_flight_time.display',model.warrantyFlightTime)}}</span>
				</li>
			</ul>
		</div>
	</a-card>
</template>

<script>
import DroneIcon from "@components/icon/DroneIcon.vue"
import ImageLoader from "@components/common/ImageLoader.vue"
import {getStatusActiveByDeletedAt} from "@utils/commonUtil"

export default {
	components : {
		ImageLoader,DroneIcon
	} ,
	props : {
		loading : {
			type : Boolean,
			default: false
		} ,
		model : {
			type : null,
			default : () => []
		} ,
		numDrones : {
			type : Number,
			default : 0
		} ,
		showUpdate : {
			type: Boolean,
			default: false
		}
	},

	computed : {
		canUpdate() {
			return this.showUpdate && this.model.id && this.$authorize('update', 'droneModel');
		} ,
		droneModelStatus(){
			return getStatusActiveByDeletedAt(this.model?.deletedAt)
		},
		isDroneModelActive(){
			return !this.model.deletedAt
		},
		cardCssClasses() {
			return [
				"drone-model-card","mycard", "mycard-with-details",
				{
					"mycard-inactive" : !this.isDroneModelActive
				}
			]
		},
	} ,
	methods : {
		handleUpdateClick() {
			this.$open({name:'drone_model/update',params : {id : this.model.id}})
		}
	}
}
</script>
